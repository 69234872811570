/********* new footer css *************/
.plt_address_box{
    cursor: pointer;
}
.ft-mb-3 {
    margin-bottom: 1rem;
    margin-top: 10px;
}
.contact-block-section span {
    font-size: 16px;
}
.bottom-bar a {
    color: #999;
}

.ttfotter-logo img {
    width: 100%;
}
.footer2 {
    font-size: 13.5px;
}
.sj_footer_link_title svg {
    display: none;
}
.f_link_toggle{
    display: block;
}
.sj_footer_link_title.ac_active h2 {
    color: black;
}

.sj_footer_link_title.ac_active svg {
    color: rgb(0, 0, 0);
}
.sj_footer_link_title.ac_active span svg {
    transform: rotate(180deg);
    transition: all 0.5s;
}
.sj_footer_link_title span svg {
    transition: all 0.5s;
}

.footerfull {
    width: 100%;
    background: #efefef;
    padding: 0;
    display: flex;
    margin: 0;
    align-items: center;
}

.contact-page-info {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 748px; */
    padding:75px 40px;
}
.contact-page-info {
    max-width: none;
    line-height: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.mrb15 {
    margin-bottom: 15px;
}


.title24 {
    font-size: 24px;
}
.font-bold {
    font-weight: 600;
}
.title48 {
    font-size: 48px;
}
.contact-block-section {
    margin-top: 5px;
    margin-left: 0px;
}
.pr-top svg {
    position: relative;
    top: -4px;
}
.dark.line-32 {
    line-height: 29px !important;
}
.contact-box {
    position: relative;
    padding-left: 45px;
    padding-top: 10px;

    clear: both;
}
.contact-box > span.dark {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    color: #000000;
}

span.desc.cursor {
    cursor: pointer;
}
.contact-block-section span {
    font-family: Nunito Sans;
    font-size: 14px;
}
.desc {
    color: rgb(0, 0, 0);
    margin: 0;
    line-height: 24px;
}
.contact-box.contact-address-box label {
    font-weight: 500;
    margin-bottom: 2px !important;
}
.contact-box > span.dark.time {
    font-size: 15px;
}
/* .b-top1:after {
    content: "";
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #9f9f9f;
    margin: auto;
    right: 0;
} */
.b-top1 {
    position: relative;
}

.f_bt {
    font-weight: 500;
    margin-bottom: 0 !important;
    font-size: 16px;
}

.contact-block-section span {
    font-size: 15px;
}

.footerfullright {
    /* height: 748px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 15px;
    /* background: #000; */
}
.newsletter {
    padding-top: 25px;
    padding-bottom: 25px;
    /* margin-bottom: -50px; */
}
.newsletter i.fa.fa-envelope {
    font-size: 30px;
    margin-bottom: 15px;
    color: #000000;
}
.block-footer2 > h2 {
    margin-bottom: 5px;
    font-size: 40px;
}
.newsletter p {
    font-size: 13px;
    margin-bottom: 25px;
}
.block-footer2 .form-newsletter2 {
    margin-top: 14px;
    margin-bottom: 70px;
}
.input-field {
    /* width: 80%; */
    width: 75%;
    display: inline-block;
    display: inline-flex;
}
.form-newsletter2 input[type=email] {
    height: 46px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    background: 0 0;
    outline: none;
    box-shadow: none;
    padding-left: 14px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.subscribe-filed {
    display: inline-block;
}
.form-newsletter2 input[type=submit] {
    border: 0 solid #999;
    background: #000000;
    color: #fff;
}
.form-newsletter2 input[type=submit] {
    text-transform: uppercase;
    height: 46px;
    padding: 0 25px;
    font-size: 12px;
    letter-spacing: 1px;
    /* border-radius: 30px; */
    margin-left: -26px;
    top: 0px;
    position: relative;
    transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    -webkit-transition: all .4s;
    -ms-transition: all .4s;
}
.list-none {
    list-style: none;
    margin: 0;
    padding: 0;
}
.contact-block-section span a {
    color: inherit;
}

.block-footer3 li {
    padding-left: 0px;
}

.contact-foter2 li {
    margin: 4px 0 0;
    position: relative;
    padding-left: 0px;
}
#footer a.dark {
    color: rgb(0, 0, 0) !important;
}
.main-footer2 h2 {
    color: #000;
}

#footer a.dark {
    font-size: 15px;
    line-height: 30px;
}
.main-footer2 a {
    text-transform: capitalize;
}
.bottom-bar {
    background: #111111;
    color: #fff;
}

.bottom-bar {
    font-size: 12.5px;
    padding: 15px;
}

.copyright-footer span {
    color: #999;
}
.copyright-footer span {
    font-size: 14px;
}
.opaci {
    opacity: 0.8;
}
.social-network-footer {
    text-align: right;
}

.block-footer2 .social-network-footer a {
    color: #999;
}
.block-footer2 .social-network-footer a {
    margin: 0 3px;
    line-height: 33px;
    margin-bottom: 0px;
}
.social-network-footer a {
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
}
.inline-block {
    display: inline-block;
    vertical-align: top;
}
.round {
    border-radius: 50%;
}
.social-network-footer a i {
    font-size: 16px;
}
.footterbox p {
    margin-bottom: 0;
}
/***************************************/

.rcs_footer {
    padding: 40px 0px;
    border-top: 0px solid #f7f7f7;
    padding-bottom: 30px;
}
.rcs_footer_logo img {
    width: 270px;
}
.rcs_footer_content_one a img {
    max-width: 50%;
}
.rcs_footer_content h2 {
    font-family: 'Montserrat' !important;
    font-size: 20px;
    font-weight: 600;
}
.rcs_footer_logo img {
    width: 270px;
}
.rcs_footer_content h2 {
    font-size: 24px;
    font-weight: 500;
    color: #ffff;
    letter-spacing: 1.2px;
    margin-top: 15px;
}
ul.rcs_footer_list li a {
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
}
ul.rcs_footer_list li {
    padding-bottom: 12px;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}
ul.rcs_footer_socail {
    display: flex;
}
ul.rcs_footer_list p {
    margin-bottom: 10px;
    font-size: 17px;
    color: #ffffff;
}
.rcs_footer_bottom_content {
    background: #0f2248;
    padding: 15px 0px;
}
ul.rcs_footer_socail li svg {
    height: 28px;
    width: 35px;
    cursor: pointer;
}
ul.rcs_footer_list li .dropdown-menu.show img {
    width: 20px;
}
ul.rcs_footer_list li .dropdown-menu {
    min-width: 100px;
}
.rcs_footer_bottom {
    background-color: #081632;
    color: #fff;
    padding: 20px 0;
    width: 100%;
}
.rcs_footer_bottom p {
    margin: 0;
    text-align: center;
    font-size: 16px;
}
.rcs_footer_bottom p a {
    color: #fff;
    font-weight: 600;
}
.rcs_dropdown_lang_footer button,.rcs_dropdown_lang_footer button:hover,.rcs_dropdown_lang_footer button:focus,.rcs_dropdown_lang_footer.show button {
    border: 1px solid #eaeaea !important;
    padding: 5px 10px !important;
}

.rcs_footer_content_one p {
    color: white;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 95%;
    font-weight: 300;
    font-size: 16px;
}

.rcs_footer_content_one_social_icn_div svg {
    color: white;
    font-size: 22px;
}
.rcs_footer_content_one_social_icn_div {
    width: 55px;
    height: 55px;
    background: #081632;
    border: 1px solid #ffffff54;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rcs_footer_content_one_social_icn ul li {
    margin-right: 12px;
}
.rcs_footer_content_one_social_icn ul {
    display: flex;
}
.rcs_footer_logo_img_div img {
    width: 100%;
    height: 100%;
}
.rcs_footer_content_one p a {
    color: #fff;
    font-weight: 500;
    text-decoration: underline;
}
.rcs_footer_logo_img_div {
    width: 100px;
    height: 55px;
    background: white;
    margin: 0 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    opacity: 0.7;
    justify-content: center;
}
.rcs_footer_logo_img_div a {
    height: 50px;
}
.rcs_footer_logo_main ul {
    display: flex;
    margin-right: 10px;
}

.rcs_footer_logo_img_div:hover {
    opacity: 1;
    transition: 0.3s;
}
.rcs_footer_content.rcs_info ul.rcs_footer_list li {
    padding-bottom: 12px;
}
.rcs_footer_content_one_social_icn {
    margin-top: 1rem;
}
.rcs_footer_logo_main {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.rcs_footer_content_one_social_icn_div a {
    line-height: 0;
}
.gs_footer_content .Mui-expanded .MuiAccordionSummary-gutters {
    background: #0f2248;
    margin-bottom: 10px;
    color: white !important;
}
/*Responsive css*/
@media (min-width: 320px) and (max-width: 991px) {
   body .rcs_news_modal .modal-content {
        height: 360px;
      }
      body .rcs_news_modal .gs_news_form {
        bottom: -320px;
      }
    .gs_footer_content .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
        border-radius: 0 !important;
        box-shadow: none !important;
    }
    .gs_footer_content .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
        width: 100%;
    }
    .gs_footer_content .MuiButtonBase-root {
        display: flex !important;
    }
    .gs_footer_content .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 0px 0;
    }
    .gs_footer_content .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
        min-height: 50px;
    }
    .gs_footer_content .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
        margin: 0px 10px;
    }
    .gs_footer_content .css-15v22id-MuiAccordionDetails-root {
        padding: 0px 0px 16px;
    }
    .gs_footer_content .css-o4b71y-MuiAccordionSummary-content {
        margin: 16px 10px;
    }
    .rcs_footer {
        padding-bottom: 0;
        padding: 0;
    }
    .rcs_footer_bottom p {
        font-size: 12px;
    }
    .rcs_footer_logo img {
        width: 200px;
    }

    /*Server css*/
    .gs_footer_content .css-67l5gl {
        border-radius: 0 !important;
        box-shadow: none !important;
    }
    .gs_footer_content .css-67l5gl {
        width: 100%;
    }
    .gs_footer_content .css-67l5gl.Mui-expanded {
        margin: 0px 0;
    }
    .gs_footer_content .css-1iji0d4.Mui-expanded {
        min-height: 50px;
    }
    .gs_footer_content .css-17o5nyn.Mui-expanded {
        margin: 0px 0;
    }
    .gs_footer_content .css-u7qq7e {
        padding: 0px 0px 16px;
    }
    .gs_footer_content .css-17o5nyn {
        margin: 16px 0;
    }
    /*Server css*/
}

@media (min-width: 992px) and (max-width: 1024px) {
    ul.rcs_footer_list li a {
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 300;
    }   
    .b-top1:after {
        top: 17px;
    } 
    .ad_footer_email_Phone {
        margin-top: 30px;
    }
    .rcs_footer_content_one_social_icn ul li {
        margin-right: 6px;
    }
    .rcs_footer {
        padding: 40px 0px;
        border-top: 0px solid #f7f7f7;
        padding-bottom: 40px;
    }
.logo img {
    width: 100%;
}
.rcs_footer_content_one p {
    color: white;
    margin-top: 10px;
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0;
}
.rcs_footer_content_one_social_icn_div {
    width: 40px;
    height: 40px;
    background: #081632;
    border: 1px solid #ffffff54;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rcs_footer_content_one_social_icn_div svg {
    font-size: 18px;
}
.rcs_footer_content.rcs_info ul.rcs_footer_list li {
    padding-bottom: 10px;
}

}

@media (min-width: 768px) and (max-width: 992px) {
    .footterbox {
        text-align: center;
    }
    .b-top1:after {
        top: 10px;
        width: 90%;

    }
    .ad_footer_email_Phone {
        padding-top: 10px;
    }
    .contact-page-info p {
        margin-bottom: 5px;
    }
    .title48 {
        font-size: 40px;
    }
    .footernew .contact-page-info {
        padding: 15px 30px;
    }

    .contact-box {
        padding-top: 15px;
    }
    .contact-block-section {
        margin-top: 1px;
    }
    .footernew .title48 {
        margin-bottom: 5px;
    }
    .contact-block-section {
        margin-top: 0px;
        margin-left: -15px;
    }
    .ad_opening_hours {
        margin-top: 20px;
    }
    .contact-page-info p {
        margin-bottom: 0;
    }
    .social-network-footer {
        text-align: center;
    }
    .block-footer2 > h2 {
        margin-bottom: 5px;
        font-size: 34px;
    }
    .block-footer2 .form-newsletter2 {
        margin-bottom: 30px;
    }
/* .rcs_footer_content_one {
    text-align: center;
}
.rcs_footer_content_one p {
    color: white;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 100%;
    font-weight: 300;
} */

}

@media (min-width: 320px) and (max-width: 767px) {
    .f_link_toggle{
        display: none;
    }
    .b-top1:after {
        top: 12px;
    }
    .ad_footer_email_Phone {
        margin-top: 0px;
        grid-template-columns: auto;
    }
    .sj_footer_link_title.ac_active h2 {
        color: rgb(0, 0, 0);
        transition: all 0.2s;
    }
    .title48 {
        font-size: 30px;
    }
    .block-footer2 > h2 {
        font-size: 30px;
    }
    .title24 {
        font-size: 20px;
    }
    .mrb15 {
        margin-bottom: 10px;
    }
    .sj_footer_link_title h2 {
        transition: all 0.2s;
    }
    .rcs_footer_content_one_social_icn ul li {
        margin-right: 8px;
    }
    .ttfotter-logo img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        background-position: 50% 50%;
        display: none;
    }
    .footerfull {
        display: inherit;
    }
    .contact-page-info {
        padding: 40px 30px;
    }
    .contact-page-info p {
        margin-bottom: 0;
    }
    .block-footer2 .form-newsletter2 {
        margin-bottom: 20px;
    }
    .input-field {
        width: 100%;
        margin-bottom: 20px;
    }
    .form-newsletter2 input[type=submit] {
        width: 100%;
        margin-left: 0px;
    }
    .subscribe-filed {
        display: inline-block;
        width: 100%;
    }
    .block-footer3 ul.list-none.contact-foter2 {
        padding: 0px 0px 0;
    }
    .block-footer3 {
        margin-bottom: 20px;
    }
    .social-network-footer {
        text-align: center;
        margin-top: 5px;
    }
    .sj_footer_link_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .sj_footer_link_title svg {
        font-size: 20px;
        display: block;
    }
}

@media (max-width: 991px){

    .rcs_footer_content_one_social_icn {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        margin-bottom: 0.8rem;
    }
    .rcs_footer_content_one p {
        color: white;
        margin-top: 10px;
        width: 100%;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 10px;
    }
    .rcs_footer_content_one {
        text-align: center;
        width: 90%;
        margin: auto;
    }
    .rcs_footer_list.contact-foter2 {
        padding: 0px 30px 0;
    }
    ul.rcs_footer_list li a {
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 300;
    }
    ul.rcs_footer_list li {
        padding-bottom: 10px;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
    }
    .rcs_footer_content_one .logo img {
        width: 100%;
    }
    
    .rcs_footer_content_one .logo {
        width: 90%;
        margin: auto;
    }
    .rcs_footer_content_one_social_icn_div {
        width: 45px;
        height: 45px;
    }
    .rcs_footer_content_one_social_icn_div svg {
        color: white;
        font-size: 18px;
    }
    ul.list-none.contact-foter2 {
        padding: 0px 30px 0;
    }
    
    ul.list-none.contact-foter2 p {
        margin-bottom: 5px;
        margin-top: 10px;
        font-size: 14px;
    }
    ul.list-none.contact-foter2 li {
        font-size: 12px;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .rcs_footer_content_one_social_icn_div svg {
        font-size: 18px;
    }
    .contact-box {
        padding-top: 8px;
    }

    #footer a.dark {
        font-size: 12px;
        line-height: 25px;
    }
    .f_bt {
        font-size: 13px;
    }
    .contact-box.contact-address-box label {
        font-weight: 500;
    }
    .ft-mb-3 {
        margin-bottom: 0.4rem;
        margin-top: 5px;
    }
    .contact-block-section span {
        font-size: 12px;
    }
    .ft_day_text p {
        font-size: 11px;
        font-weight: 500;
    }
    .ttfotter-logo img {
        width: 100%;
    }
    .title24 {
        font-size: 17px;
        margin-bottom: 5px;
    }
    .ad_opening_hours {
        margin-top: 15px;
    }
    .title48 {
        font-size: 24px;
        margin-bottom: 0px;
    
    }
    .ad_opening_hours p {
        font-size: 12px;
    }
  
    .ad_opening_hours p span {
        font-size: 14px;
    }
    .desc {
        line-height: 18px;
    }
    .contact-page-info {
        padding:75px 20px;
    }
    .block-footer2 > h2 {
        margin-bottom: 5px;
        font-size: 30px;
    }
    .newsletter p {
        margin-bottom: 15px;
    }
}

@media (min-width: 575px) and (max-width: 991px) {
    .rcs_footer_content_one .logo {
        width: 55%;
        margin: auto;
    }
    .rcs_footer_content_one p {
        color: white;
        margin-top: 10px;
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }
    .rcs_footer_content_one_social_icn_div {
        width: 55px;
        height: 55px;
    }
    .rcs_footer_content_one_social_icn_div svg {
        color: white;
        font-size: 22px;
    }
    .gs_footer_content p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
        font-size: 20px;
    }
    ul.rcs_footer_list li a {
        font-size: 16px;
    }
    .rcs_footer_bottom p {
        font-size: 14px;
    }
}

/*Responsive css*/


/*news letter*/
.mj_newsletter_content .mj_close_btn, .mj_newsletter_content .mj_close_btn:hover, .mj_newsletter_content .mj_close_btn:active, .mj_newsletter_content .mj_close_btn:focus {
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    float: right !important;
    position: absolute;
    top: -10px;
    right: -10px;
  }
  .mj_newsletter_content{
    position: relative;
  }
  .rcs_news_modal .modal-content {
    /* background-image: url(../images/newsletter12.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 530px;
  }
  .rcs_news_modal .gs_news_form {
    position: absolute;
    bottom: -480px;
  }
  .rcs_news_modal .gs_news_form input {
    width: 100%;
    margin-bottom: 12px;
    background-color: #fff !important;
    padding: 21px !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    outline: none !important;
  }
  .mj_newsletter_content .mj_sub_btn, .mj_newsletter_content .mj_sub_btn:hover, .mj_newsletter_content .mj_sub_btn:active, .mj_newsletter_content .mj_sub_btn:focus {
    width: 100%;
    background-color: #000 !important;
    color: #fff !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 9px 10px !important;
    border: 1px solid #fff;
  }
  .mj_newsletter_content input::placeholder {
    color: #000 !important;
  }
  /*news letter*/